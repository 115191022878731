<style>
    .item-deleted {
        background-color: #ffaeae;
    }
    .date-row {
        cursor: pointer;
    }
</style>

<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="list_server_date"
                        item-key="date"
                        class="elevation-1"
                        :item-class="class_date_row"
                        :items-per-page="15"
                        @click:row="handle_click"
                        >
                        <template v-slot:top>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="col-md-4">
                                    <router-link :to="'/orders/create'" class="btn btn-success font-weight-bold btn-sm mr-2">
                                        <v-icon left>mdi-plus</v-icon> Tạo đơn
                                    </router-link>
                                </div>
                            </div>
                        </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Chọn ngày'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.q || "",
            status: this.$route.status || 0,
            headers: [
                { text: "Ngày", sortable: false, value: 'date_str'},
                { text: "Số đơn", sortable: false, value: 'order_count'},
            ],
        };
    },
    watch: {
        
    },
    computed: {
        dates() {
            var arr = [];
            for(var i = 0; i < 100; i++) {
                var tmp_date = new Date();
                tmp_date.setDate(tmp_date.getDate() - i);

                var tmp_start = new Date();
                tmp_start.setDate(tmp_start.getDate() - i);
                tmp_start.setHours(0);
                tmp_start.setMinutes(0);
                tmp_start.setSeconds(0);

                var tmp_end = new Date();
                tmp_end.setDate(tmp_end.getDate() - i);
                tmp_end.setHours(23);
                tmp_end.setMinutes(59);
                tmp_end.setSeconds(59);

                arr.push({
                    date_str: tmp_date.toLocaleDateString("vi-VN"),
                    date_start: Math.floor(tmp_start.getTime() / 1000),
                    date_end: Math.floor(tmp_end.getTime() / 1000),
                });
            }
            return arr;
        },
        list_server_date() {
            var list_date = this.$store.getters['orders/get_list_date'];
            list_date.sort((a, b) => new Date(a.Order_date) > new Date(b.Order_date) ? -1 : 1);
            var arr = [];

            for(var i in list_date) {
                var tmp_date = new Date(list_date[i].Order_date);

                var tmp_start = new Date(list_date[i].Order_date);
                tmp_start.setHours(0);
                tmp_start.setMinutes(0);
                tmp_start.setSeconds(0);

                var tmp_end = new Date(list_date[i].Order_date);
                tmp_end.setHours(23);
                tmp_end.setMinutes(59);
                tmp_end.setSeconds(59);

                arr.push({
                    date_str: tmp_date.toLocaleDateString("vi-VN"),
                    date_start: Math.floor(tmp_start.getTime() / 1000),
                    date_end: Math.floor(tmp_end.getTime() / 1000),
                    order_count: list_date[i].Order_count
                });
            }

            return arr;
        }
    },
    components: {
    },
    methods: {
        handle_click(row) {
            // console.log("row", row.date_start);
            // console.log("row2", row.date_end);
            if(!row) return;
            if(!row.date_start || !row.date_end) return;

            this.$router.push({
                name: "orders-index",
                query: { page: 1, limit: 15, q: "", status: "", start: row.date_start, end: row.date_end }
            }).catch(() => { });
        },
        class_date_row: function () {
            return "date-row";
        }
    },
    mounted() {
        // this.$store.dispatch(SET_BREADCRUMB, [ { title: "Đơn hàng", route: "index" }, { title: "Tất cả" } ]);
        this.$store.dispatch('orders/get_list_date');
    }
};
</script>

